import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { useHistory, useLocation } from "react-router-dom"
import { useDispatch } from "react-redux"
import { useUserInfo } from "../hooks/useUserInfo"
import { showLoginPopup } from "../store/slices/layerSlice"
import { isPageTop } from "./utils"
import ChatWidget from "../chat/ChatWidget"
import { ReactComponent as ArrowImg } from "../images/go-to-top.svg"
import { ReactComponent as WriteImg } from "../images/write.svg"

const DEFAULT_COMMUNITY_CATEGORY_ID = "templates"

export default function FloatingButtons() {
  const [showGoToTop, setShowGoToTop] = useState(!isPageTop())

  const history = useHistory()
  const dispatch = useDispatch()

  const { loggedIn } = useUserInfo()

  const location = useLocation()
  const isCommunityPage = location.pathname.startsWith("/community")
  const pathSegments = location.pathname.split("/")
  const communityCategoryId = pathSegments[2] || null
  const communityPostId = pathSegments[3] || null
  const showWriteButton =
    isCommunityPage &&
    communityCategoryId !== "notice" &&
    communityPostId == null

  useEffect(() => {
    const handleScroll = () => {
      setShowGoToTop(!isPageTop())
    }

    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  const onClickCommunityWrite = () => {
    const categoryId = communityCategoryId || DEFAULT_COMMUNITY_CATEGORY_ID

    if (loggedIn) {
      history.push(`/community/${categoryId}/write`)
    } else {
      dispatch(
        showLoginPopup({
          redirect: `/community/${categoryId}/write`,
          message: `환영합니다! 로그인 하여\n커뮤니티를 자유롭게 즐겨보세요.`
        })
      )
    }
  }

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" })
  }

  return (
    <div className="fixed right-2 md:right-6 bottom-6 flex flex-col gap-y-2 items-end z-50">
      <ChatWidget />
      {showWriteButton && (
        <CommunityWriteButton onClick={onClickCommunityWrite}>
          <WriteImg />
        </CommunityWriteButton>
      )}
      <GoToTop className={showGoToTop ? "" : "hidden"} onClick={scrollTop}>
        <ArrowImg width={64} height={62} />
      </GoToTop>
    </div>
  )
}

const CommunityWriteButton = styled.div`
  width: 64px;
  height: 64px;
  background: #333333;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  filter: drop-shadow(0px 6px 16px rgba(0, 0, 0, 0.16));
  @media (min-width: 801px) {
    display: none;
  }
`

const GoToTop = styled.div`
  cursor: pointer;
  animation: fadeIn 0.5s;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @media (max-width: 800px) {
    right: 16.16px;
    bottom: ${({ aboveOtherButton }) =>
      aboveOtherButton ? "calc(24px + 8px + 57px)" : "24px"};
  }
`
