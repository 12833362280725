import React, { useEffect } from "react"
import { useUserInfo } from "../hooks/useUserInfo"
import { ReactComponent as SirenImg } from "../images/siren.svg"

export default function ReportMenu({
  messageListRef,
  isOpen,
  onClose,
  onReport,
  hasReported,
  onBlind
}) {
  const { authType } = useUserInfo()

  useEffect(() => {
    if (isOpen) {
      // Prevent scrolling on MessageList
      if (messageListRef.current) {
        messageListRef.current.style.overflow = "hidden"
      }
    } else {
      // Re-enable scrolling
      if (messageListRef.current) {
        messageListRef.current.style.overflow = "auto"
      }
    }
  }, [isOpen, messageListRef])

  useEffect(() => {
    if (hasReported) {
      const timer = setTimeout(() => {
        onClose()
      }, 800)
      return () => clearTimeout(timer)
    }
  }, [hasReported, onClose])

  if (!isOpen) return null

  const scrollTop = messageListRef.current?.scrollTop || 0

  const handleOverlayClick = e => {
    // Only close if the click was directly on the overlay
    if (e.target === e.currentTarget) {
      onClose()
    }
  }

  return (
    <div
      className="absolute inset-0 bg-black/10 flex flex-col items-center justify-center z-50"
      style={{
        transform: `translateY(${scrollTop}px)`
      }}
      onClick={handleOverlayClick}
    >
      <div
        className={`flex items-center bg-white rounded-[4px] py-3 pl-4 pr-6 shadow-2xl ${
          hasReported ? "cursor-default" : "cursor-pointer"
        }`}
        onClick={onReport}
      >
        <SirenImg />
        <p className="ml-2 text-sm">
          {hasReported ? "신고 완료" : "불쾌해요. 메시지 신고할래요"}
        </p>
      </div>
      {authType === "A" && (
        <div className="mt-5 text-white cursor-pointer" onClick={onBlind}>
          가리기
        </div>
      )}
    </div>
  )
}
