export const getGreeting = () => {
  const now = new Date()
  const hour = now.getHours()
  const month = now.getMonth() + 1 // 0-based index
  const date = now.getDate()
  const day = now.getDay() // 0 (Sunday) - 6 (Saturday)
  const year = now.getFullYear()

  const getRandomMessage = messages => {
    return messages[Math.floor(Math.random() * messages.length)]
  }

  const getDrinkAdjective = month => {
    if (month >= 11 || month <= 2) return "따뜻한"
    if (month >= 7 && month <= 9) return "시원한"
    return "향긋한"
  }

  // Christmas (Dec 25)
  if (month === 12 && date === 25) {
    return getRandomMessage([
      "메리 크리스마스! 🎄 오늘 특별한 계획이 있다면 공유해주세요~",
      "즐거운 크리스마스! 🎅 올해는 어떤 선물을 준비하셨나요? 🎁",
      "따뜻한 마음을 나누는 크리스마스! 🎄 맛있는 거 드시러 가실 예정인가요?"
    ])
  }

  // Chuseok 2025: October 5-7
  if (month === 10 && date >= 5 && date <= 7) {
    return getRandomMessage([
      "즐거운 추석 명절 보내세요! 🌕 고향 가시는 길 교통체증은 없길 바라요~ 다들 어디로 가시나요? 🚗",
      "풍성한 한가위 되세요! 🌕 명절 연휴 계획은 세우셨나요? 🍁",
      "달님처럼 환한 미소가 가득한 추석 되세요! 🌕 추천하고 싶은 명절 선물이 있다면 공유해주세요 🎁",
      "민족의 대명절 추석! 연휴 계획 공유해주세요~ 혹시 해외여행 계획이 있으신가요? ✈️"
    ])
  }

  // Year-end (Dec 26-31)
  if (month === 12 && date > 25) {
    return getRandomMessage([
      `${year}년 마무리 잘 하고 계신가요? 연말 결산하기 바쁘시죠? 힘내세요! 🌟 새해 목표는 세우셨나요?`,
      `${year}년 마지막이 다가오네요. 올해 가장 기억에 남는 순간은 무엇인가요? ⭐`,
      `${year}년의 아쉬움은 뒤로 하고, 희망찬 새해를 기다려보아요 ✨`
    ])
  }

  // New Year (Jan 1-7)
  if (month === 1 && date <= 7) {
    return getRandomMessage([
      `새해 복 많이 받으세요! ${year}년 목표는 무엇인가요? 같이 공유해볼까요? 🎊`,
      `${year}년이 밝았어요! 올해는 어떤 변화를 계획하고 계신가요? 🎯`,
      `새해에는 모든 소원이 이루어지시길! ✨ 올해 가장 이루고 싶은 목표 하나만 말씀해주세요~`
    ])
  }

  // Weekday specific greetings
  if (day >= 1 && day <= 5) {
    // Monday to Friday
    if (hour >= 9 && hour < 10) {
      const drinkAdj = getDrinkAdjective(month)
      return getRandomMessage([
        `${drinkAdj} 커피 한잔으로 월요병 이겨내봐요! 🌞 오늘 아침 커피는 어디서 드셨나요?`,
        "상쾌한 아침입니다! 🌞 출근길은 어떠셨나요?",
        "새로운 아침이 밝았습니다! 🌅 오늘 중요한 미팅 있으신가요? 다들 파이팅해요!",
        "굿모닝! 💪 재택근무하시는 분들도 계신가요? 재택근무 꿀팁 공유해요!"
      ])
    } else if (hour >= 12 && hour < 13) {
      return getRandomMessage([
        "점심 시간입니다! 🍚 오늘 점심 메뉴 추천해주세요~",
        "즐거운 점심시간! 🍱 맛있어 보이는 메뉴가 있다면 공유해주세요!",
        "벌써 점심이네요! 🍜 주변 맛집 추천 부탁드려요~ 새로 생긴 가게도 있나요?",
        "점심 맛있게 드세요! 🌞 혹시 배달음식 시키실 분?"
      ])
    } else if (hour >= 17 && hour < 19) {
      return getRandomMessage([
        "퇴근 시간이 다가왔네요! 🌙 오늘도 야근하시는 분 계신가요? 다들 화이팅!",
        "퇴근 후 약속 있으신가요? 🌆",
        "오늘 저녁 퇴근하고 뭐하시나요? 🌅 추천하고 싶은 넷플릭스 드라마 있으신가요?"
      ])
    } else {
      const timeBasedGreetings = {
        morning: getRandomMessage([
          "활기찬 아침입니다! 아침 루틴이 있으신가요? 공유해주세요 💪",
          "상쾌한 아침, 오늘 날씨는 어떤가요? 🌞",
          "아침부터 회의 있으신 분? 오늘도 화이팅하세요! 💫"
        ]),
        afternoon: getRandomMessage([
          "오후에도 힘내세요! ☕ 오늘 업무는 잘 진행되고 있나요?",
          "오늘 점심은 어떠셨나요? 🌤️ 맛있는 메뉴 있었다면 공유해주세요!",
          "잠시 일어나서 스트레칭 한번 어떠세요? 🧘‍♀️"
        ]),
        evening: getRandomMessage([
          "수고하신 하루! 퇴근 후 계획 있으신가요? 🌆",
          "오늘 하루도 잘 마무리하셨나요? 🌙 피곤하시죠? 오늘의 힐링템은 뭔가요?",
          "저녁 약속 있으신 분들 계신가요? ⭐"
        ]),
        night: getRandomMessage([
          "늦은 시간까지 수고하십니다! 🌙",
          "야근하시는 분들 힘내세요! ☕",
          "늦은 밤까지 고생하시는군요! 🌟 퇴근하고 가실 때 안전하게 가세요!"
        ])
      }

      if (hour >= 5 && hour < 12) return timeBasedGreetings.morning
      if (hour >= 12 && hour < 17) return timeBasedGreetings.afternoon
      if (hour >= 19 && hour < 22) return timeBasedGreetings.evening
      return timeBasedGreetings.night
    }
  }

  // Weekend greetings
  if (day === 0 || day === 6) {
    if (hour < 12) {
      return getRandomMessage([
        "주말 아침 잘 보내고 계신가요? 😊 주말에도 출근하시는 분들 계신가요?",
        "즐거운 주말 아침이에요! 🌞 오늘은 어디 가시나요? 주말 나들이 장소 추천해주세요~",
        "주말엔 늦잠도 좋죠~ 😴 브런치 맛집 아시는 분 계신가요?",
        "여유로운 주말 아침! 🌞 주말에 뭐하시나요?"
      ])
    } else {
      return getRandomMessage([
        "즐거운 주말 보내세요! 🎵 주말에 뭐하시나요? 영화 추천 받아요~ ✨",
        "주말에도 이렇게 만나요! 🌈 다들 주말 계획은 있으신가요?",
        "우리의 소중한 주말! 🎵 이번 주말 뭐하고 지내세요?",
        "활기찬 주말! 🌟 주말에 운동하시는 분들 계신가요?"
      ])
    }
  }

  // Default greeting
  return getRandomMessage([
    "안녕하세요! 오늘 기분은 어떠신가요? 😊",
    "어서오세요! 오늘의 TMI 한가지만 공유해주세요~ 🌟"
  ])
}