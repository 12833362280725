import React, { useEffect, useState, useRef } from "react"
import { useHistory } from "react-router-dom"
import styled from "styled-components"
import OrderByRadio from "../common/OrderByRadio"
import { db, logUserEvent } from "../common/firebase.utils"
import { ORDER, ORDER_BY } from "../common/constants"
import { ReactComponent as FindImg } from "../images/find.svg"
import Empty from "../common/Empty"
import CommunityListView from "./CommunityListView"
import skeletonImg from "../images/post-skeleton.svg"
import skeletonImgM from "../images/post-skeleton-m.svg"
import CheckBottom from "../common/CheckBottom"
import { useUserInfo } from "../hooks/useUserInfo"
import { useDispatch } from "react-redux"
import { showLoginPopup } from "../store/slices/layerSlice"

function List({ category, banner }) {
  const LOAD_COUNT = 5
  const DEFAULT_ORDER_BY = ORDER_BY.CREATED_AT
  const DEFAULT_ORDER = ORDER.DESC

  const history = useHistory()
  const [posts, setPosts] = useState()
  const [gettingMore, setGettingMore] = useState(false)
  const [noMoreToLoad, setNoMoreToLoad] = useState(false)
  const [options, setOptions] = useState({
    orderby: DEFAULT_ORDER_BY,
    order: DEFAULT_ORDER
  })
  const { loggedIn } = useUserInfo()
  const dispatch = useDispatch()
  const isNotice = category.id === "notice"
  const isEmpty = posts?.length === 0
  const lastVisible = useRef(null)

  useEffect(() => {
    lastVisible.current = null
    if (posts?.length > 0) {
      setPosts()
    }
    if (isNotice) {
      getNotices()
    } else {
      getPosts()
    }
  }, [category, options])

  const getNotices = isGettingMore => {
    let query = db
      .collection("notices")
      .where("hide", "==", false)
      .limit(LOAD_COUNT)
      .orderBy(ORDER_BY.CREATED_AT, ORDER.DESC)
    if (isGettingMore) {
      query = query.startAfter(lastVisible.current)
    }
    query
      .get()
      .then(snapshot => {
        const _posts = isGettingMore ? [...posts] : []
        snapshot.docs.forEach(doc => {
          _posts.push({ id: doc.id, ...doc.data() })
          lastVisible.current = doc
        })
        setNoMoreToLoad(snapshot.size < LOAD_COUNT)
        setPosts(_posts)
        if (isGettingMore) {
          setGettingMore(false)
        }
      })
      .catch(e => {
        console.error(e)
        window.alert("공지사항을 불러오는 데 실패했습니다.")
      })
  }

  const getPosts = isGettingMore => {
    const { orderby = DEFAULT_ORDER_BY, order = DEFAULT_ORDER } = options

    let query = db
      .collection("posts")
      .where("category", "==", category.id)
      .where("deletedat", "==", null)
      .limit(LOAD_COUNT)
      .orderBy(orderby, order)
    if (isGettingMore) {
      query = query.startAfter(lastVisible.current)
    }
    query
      .get()
      .then(snapshot => {
        const _posts = isGettingMore ? [...posts] : []
        snapshot.docs.forEach(doc => {
          _posts.push({ id: doc.id, ...doc.data() })
          lastVisible.current = doc
        })
        setNoMoreToLoad(snapshot.size < LOAD_COUNT)
        setPosts(_posts)
        if (isGettingMore) {
          setGettingMore(false)
        }
      })
      .catch(e => {
        console.error(e)
        window.alert("포스트를 불러오는 데 실패했습니다.")
      })
  }

  const onClickPost = ({ id }) => {
    history.push(`/community/${category.id}/${id}`)
  }

  const onClickWrite = () => {
    if (loggedIn) {
      history.push(`/community/${category.id}/write`)
    } else {
      dispatch(
        showLoginPopup({
          redirect: `/community/${category.id}/write`,
          message: `환영합니다! 로그인 하여\n커뮤니티를 자유롭게 즐겨보세요.`
        })
      )
    }
  }

  const onClickBanner = () => {
    logUserEvent("banner_open_event")
    history.push(banner.link)
  }

  const loadMore = () => {
    setGettingMore(true)
    if (isNotice) {
      getNotices(true)
    } else {
      getPosts(true)
    }
  }

  const setOrderBy = field => {
    let _orderby = options.orderby || DEFAULT_ORDER_BY
    let _order = options.order || DEFAULT_ORDER
    if (field === _orderby) {
      _order = _order === ORDER.ASC ? ORDER.DESC : ORDER.ASC
    } else {
      _orderby = field
      _order = ORDER.DESC
    }
    setOptions({
      ...options,
      orderby: _orderby,
      order: _order
    })
    logUserEvent("click_orderby_community", {
      value: field
    })
  }

  return (
    <Container>
      {!isNotice && !isEmpty && (
        <Header
          onClickWrite={onClickWrite}
          orderBy={options.orderby}
          order={options.order}
          onChangeOrderBy={setOrderBy}
        />
      )}
      {!isNotice && banner && (
        <div id="banner" onClick={onClickBanner}>
          <img src={banner.pcImage} alt="banner" />
        </div>
      )}
      <CommunityListView
        isLoading={!posts}
        posts={posts}
        onClickPost={onClickPost}
        isNotice={isNotice}
      />
      {!isNotice && isEmpty && (
        <Empty
          text={`앗, 아직 글이 없어요.\n첫번째 글의 주인공이 되어주세요!`}
          buttonText="글쓰기"
          onClickButton={onClickWrite}
        />
      )}
      {!noMoreToLoad && posts && !isEmpty && (
        <>
          {gettingMore && (
            <img
              id="loading-skeleton"
              alt="skeleton"
              src={window.innerWidth <= 800 ? skeletonImgM : skeletonImg}
            />
          )}
          <CheckBottom onBottom={loadMore} showLoading={gettingMore} />
        </>
      )}
    </Container>
  )
}

function Header({ onClickWrite, orderBy, order, onChangeOrderBy }) {
  return (
    <HeaderContainer>
      <div className="left">
        <OrderByRadio
          orderBys={[
            ORDER_BY.REACTION,
            ORDER_BY.VIEW,
            ORDER_BY.COMMENT,
            ORDER_BY.CREATED_AT
          ]}
          currentOrderBy={orderBy}
          currentOrder={order}
          onChange={onChangeOrderBy}
        />
      </div>
      <div className="right">
        {/* <div className="search">
          <FindImg id="find" width={16} />
          <input
            type="text"
            placeholder="어떤 내용을 찾으시나요?"
            // value={input}
            // onChange={({ target }) => setInput(target.value)}
          />
        </div> */}
        <div className="write-button" onClick={onClickWrite}>
          글쓰기
        </div>
      </div>
    </HeaderContainer>
  )
}

const Container = styled.div`
  padding-top: 24px;
  padding-bottom: 48px;

  #banner {
    display: block;
    width: 100%;
    margin-bottom: 16px;
    cursor: pointer;
    img {
      width: 100%;
    }
  }

  @media (max-width: 800px) {
    padding-top: 0;
    min-height: 55vh;

    #banner {
      display: none;
    }
  }
`
const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  height: 48px;
  .right {
    display: flex;
    align-items: center;
    .search {
      position: relative;
      width: 296px;
      height: 48px;
      #find {
        position: absolute;
        left: 24px;
        top: 50%;
        transform: translateY(-50%);
      }
      input {
        border: none;
        outline: none;
        -webkit-appearance: none;
        padding: 0 24px 0 48px;
        width: 100%;
        height: 100%;
        background: #ffffff;
        border: 1px solid #ebebeb;
        box-sizing: border-box;
        border-radius: 10px;
        display: flex;
        align-items: center;
        font-weight: 400;
        font-size: 16px;
        ::placeholder {
          color: #d4d4d4;
        }
      }
    }
    .write-button {
      margin-left: 1rem;
      width: 156px;
      height: 100%;
      line-height: 48px;
      border-radius: 8px;
      font-weight: 500;
      font-size: 16px;
      text-align: center;
      border: 1px solid #5551ff;
      color: #5551ff;
      cursor: pointer;
      :hover {
        color: #ffffff;
        background: #5551ff;
      }
    }
  }
  @media (max-width: 800px) {
    height: auto;
    margin: 0;
    .left {
      width: 100%;
      height: 50px;
      padding-top: 10px;
      padding-left: 16px;
      background: #fafafa;
    }
    .right {
      .write-button {
        display: none;
      }
    }
  }
`

export default List
