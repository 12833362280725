import React from 'react'
import Gnb from './Gnb'
import Footer from "./Footer"
import styled from 'styled-components'

function PageLayout({
  clearGnbOnTop = false,
  gnbType = "default",
  children,
  backgroundColor = "transparent"
}) {
  return (
    <Container backgroundColor={backgroundColor}>
      <Gnb clearOnTop={clearGnbOnTop} type={gnbType} />
      {children}
      <Footer />
    </Container>
  )
}

const Container = styled.div`
  min-height: 100%;
  background-color: ${({backgroundColor}) => backgroundColor};
`

export default PageLayout
