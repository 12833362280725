import React, { useState, useEffect, useRef } from "react"
import styled from "styled-components"
import moment from "moment"
import {
  db,
  fieldValue,
  logUserEvent,
  Timestamp
} from "../common/firebase.utils"
import { useUserInfo } from "../hooks/useUserInfo"
import { useOnlineStatus } from "../hooks/useOnlineStatus"
import useIsMobile from "../hooks/useIsMobile"

import { ReactComponent as ChatIcon } from "../images/chat-winter.svg"
import { ReactComponent as CloseIcon } from "../images/icon-close.svg"
import { ReactComponent as EmojiIcon } from "../images/emoji-disabled.svg"
import { ReactComponent as SendIcon } from "../images/icon-send.svg"
import { ReactComponent as BadgeImg } from "../images/badge-chat.svg"
import { debounced } from "../common/utils"
import { getGreeting } from "./greetingBuilder"
import ReportMenu from "./ReportMenu"

const MAX_MESSAGE_LENGTH = 300

const MESSAGES_IN_HOURS = 8
const BLINDING_THRESHOLD = 2
const BAN_DURATION = 10 * 60000 // 10 minutes

const customEmojis = {
  smileys: {
    title: "Smileys",
    emojis: {
      ":happy:": "😊",
      ":love:": "😍",
      ":laugh:": "😂",
      ":wink:": "😉"
    }
  },
  objects: {
    title: "Objects",
    emojis: {
      ":star:": "⭐",
      ":heart:": "❤️",
      ":gift:": "🎁",
      ":fire:": "🔥"
    }
  }
}

const randomMinUser = new Date().getDate() % 3 // 0~2

/**
 * Features
 * - Real-time chat messages
 * - Emoji picker
 * - User ban system
 * - Message reporting and blinding
 * - Auto-scrolling
 * - New message badge
 */
const ChatWidget = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [message, setMessage] = useState("")
  const [messages, setMessages] = useState([])
  const [onlineCount, setOnlineCount] = useState(0)

  const [isEmojiPanelOpen, setIsEmojiPanelOpen] = useState(false)
  const [showEmojiTooltip, setShowEmojiTooltip] = useState(false)

  const [reportMenuState, setReportMenuState] = useState({
    isOpen: false,
    messageId: null,
    senderId: null,
    hasReported: false
  })
  const [longPressTimer, setLongPressTimer] = useState(null)
  const [userBanEndTime, setUserBanEndTime] = useState(null)
  const [bannedUsers, setBannedUsers] = useState(new Set())

  const [isLoading, setIsLoading] = useState(false)
  const [isSending, setIsSending] = useState(false)

  const [hasNewMessages, setHasNewMessages] = useState(false)
  const [lastViewedTimestamp, setLastViewedTimestamp] = useState(Date.now())

  const isMobile = useIsMobile()

  const messagesEndRef = useRef(null)
  const messageListRef = useRef(null)
  const messageInputRef = useRef(null)
  const userInfo = useUserInfo()

  useOnlineStatus(userInfo.userId, userInfo.loggedIn)

  const greetingRef = useRef(getGreeting())

  const isAtBottom = () => {
    if (!messageListRef.current) return true

    const { scrollTop, scrollHeight, clientHeight } = messageListRef.current
    return Math.abs(scrollHeight - clientHeight - scrollTop) < 10
  }

  useEffect(() => {
    if (isAtBottom && messagesEndRef.current && !reportMenuState.isOpen) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages])

  useEffect(() => {
    // Listen for new messages when chat is closed
    if (!isOpen) {
      const unsubscribeNewMessages = db
        .collection("messages")
        .where(
          "timestamp",
          ">",
          Timestamp.fromDate(new Date(lastViewedTimestamp))
        )
        .onSnapshot(snapshot => {
          if (snapshot.docs.length > 0) {
            setHasNewMessages(true)
          }
        })

      return () => unsubscribeNewMessages()
    }
  }, [isOpen, lastViewedTimestamp])

  useEffect(() => {
    if (isOpen) {
      // Ban status check for current user
      let unsubscribeBan = () => {}
      if (userInfo.loggedIn) {
        const banRef = db.collection("bannedUsers").doc(userInfo.userId)

        unsubscribeBan = banRef.onSnapshot(doc => {
          if (doc.exists && doc.data().banUntil > Date.now()) {
            setUserBanEndTime(doc.data().banUntil)
          } else {
            setUserBanEndTime(null)
          }
        })
      }

      return () => {
        unsubscribeBan()
      }
    }
  }, [isOpen, userInfo.loggedIn, userInfo.userId])

  useEffect(() => {
    if (isOpen) {
      setHasNewMessages(false)
      setLastViewedTimestamp(Date.now())

      messageInputRef.current.focus()

      // Subscribe to recent messages
      const hoursAgo = Timestamp.fromDate(
        new Date(Date.now() - MESSAGES_IN_HOURS * 3600000)
      )
      const unsubscribeRealtimeMessages = db
        .collection("messages")
        .where("timestamp", ">", hoursAgo)
        .orderBy("timestamp", "asc")
        .onSnapshot(snapshot => {
          setIsLoading(false)
          const newMessages = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }))
          setMessages(newMessages)
        })

      const unsubscribeOnlineUsers = db
        .collection("status")
        .where("state", "==", "online")
        .onSnapshot(snapshot => {
          setOnlineCount(snapshot.docs.length)
        })

      // Listen for all banned users
      const unsubscribeBannedUsers = db
        .collection("bannedUsers")
        .where("banUntil", ">", Date.now())
        .onSnapshot(snapshot => {
          const banned = new Set(snapshot.docs.map(doc => doc.id))
          setBannedUsers(banned)
        })

      logUserEvent("chat_opened")

      return () => {
        unsubscribeRealtimeMessages()
        unsubscribeOnlineUsers()
        unsubscribeBannedUsers()
      }
    }
  }, [isOpen])

  const handleSendMessage = debounced(async () => {
    if (isSending || !message.trim() || !userInfo.loggedIn || userBanEndTime)
      return

    setIsSending(true)

    const newMessage = {
      text: message,
      sender: userInfo.userId,
      senderName: userInfo.nickname,
      senderMembership: userInfo.membership,
      timestamp: fieldValue.serverTimestamp(),
      reporters: [],
      isBlinded: false
    }

    try {
      await db.collection("messages").add(newMessage)
      setMessage("")
      setIsEmojiPanelOpen(false)
      messageInputRef.current.value = ""
      messageInputRef.current.style.height = "auto"
    } catch (error) {
      console.error("Error sending message:", error)
    } finally {
      setIsSending(false)
    }
  }, 250)

  const handleReport = async (messageId, senderId) => {
    if (!userInfo.loggedIn) return

    const messageRef = db.collection("messages").doc(messageId)

    try {
      await db.runTransaction(async transaction => {
        const doc = await transaction.get(messageRef)
        const messageData = doc.data()

        if (messageData.reporters?.includes(userInfo.userId)) {
          // User has already reported this message
          return
        }

        const newReporters = [...(messageData.reporters || []), userInfo.userId]
        transaction.update(messageRef, { reporters: newReporters })

        const newMessageData = {
          ...messageData,
          reporters: newReporters
        }

        // add the newMessageData to the collection 'reportedMessages'
        const reportedMessagesRef = db
          .collection("reportedMessages")
          .doc(messageId)
        transaction.set(reportedMessagesRef, newMessageData)

        if (newReporters.length >= 3) {
          const banRef = db.collection("bannedUsers").doc(senderId)
          transaction.set(banRef, {
            banUntil: Date.now() + BAN_DURATION,
            reason: `Message ${messageId} reported`,
            timestamp: fieldValue.serverTimestamp()
          })
        }
      })
    } catch (error) {
      console.error("Error reporting message:", error)
    }
  }

  const openReportMenu = (messageId, senderId) => {
    setReportMenuState({
      isOpen: true,
      messageId,
      senderId,
      hasReported: false
    })
  }

  const closeReportMenu = () => {
    setReportMenuState(prev => ({
      ...prev,
      isOpen: false,
      hasReported: false
    }))
  }

  const handleReportClick = async () => {
    const { messageId, senderId } = reportMenuState
    setReportMenuState(prev => ({ ...prev, hasReported: true }))
    await handleReport(messageId, senderId)
  }

  const handleBlindClick = async () => {
    if (userInfo.authType !== "A") return
    const { messageId } = reportMenuState
    db.collection("messages").doc(messageId).update({
      isBlinded: true
    })
    closeReportMenu()
  }

  const handleTouchStart = (e, messageId, senderId) => {
    e.preventDefault() // Prevent default to avoid conflicts
    const timer = setTimeout(() => {
      openReportMenu(messageId, senderId)
    }, 500)
    setLongPressTimer(timer)
  }

  const handleTouchEnd = () => {
    if (longPressTimer) {
      clearTimeout(longPressTimer)
      setLongPressTimer(null)
    }
  }

  const insertEmoji = emojiCode => {
    if (!userInfo.loggedIn) return
    setMessage(prev => prev + " " + emojiCode + " ")
  }

  const replaceCustomEmojis = text => {
    let result = text
    Object.values(customEmojis).forEach(category => {
      Object.entries(category.emojis).forEach(([code, emoji]) => {
        result = result.replace(new RegExp(code, "g"), emoji)
      })
    })
    return result
  }

  const handleInputChange = e => {
    const textarea = messageInputRef.current

    const messageListHeight =
      messageListRef.current.getBoundingClientRect().height
    const maxHeight = messageListHeight * 0.5
    textarea.style.height = "auto" // Reset height to auto to recalculate

    if (textarea.scrollHeight > maxHeight) {
      textarea.style.height = `${maxHeight}px`
      textarea.style.overflowY = "auto" // Enable scrolling
    } else {
      textarea.style.height = `${textarea.scrollHeight}px`
      textarea.style.overflowY = "hidden" // Hide overflow
    }

    setMessage(e.target.value.slice(0, MAX_MESSAGE_LENGTH))
  }

  const handleInputKeyDown = e => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault() // Prevents adding a new line
      handleSendMessage()
    }
  }

  const getInputPlaceholder = () => {
    if (!userInfo.loggedIn) return "로그인하여 채팅에 참여하세요."
    if (userBanEndTime)
      return `3회 이상 신고되어 ${Math.ceil(
        (userBanEndTime - Date.now()) / 60000
      )}분 간 채팅이 제한됩니다.`
    return "메시지를 입력하세요."
  }

  return (
    <Container>
      <ChatButton onClick={() => setIsOpen(!isOpen)}>
        <ChatIcon
          width={isMobile ? 80 : 100}
          height={isMobile ? 77.66 : 97.08}
        />
        {hasNewMessages && <NewMessageBadge />}
      </ChatButton>
      {isOpen && (
        <ChatWindow>
          <Header>
            <h3 className="font-medium">실시간 채팅</h3>
            <div className="flex items-center ml-2 p-[6px] gap-x-[6px] bg-[#F7F5F8] rounded-[4px]">
              <div className="rounded-full w-[6px] h-[6px] bg-[#00FF11]" />
              <span className="text-xs">{onlineCount + randomMinUser}명</span>
            </div>
            <div className="flex-1" />
            <button onClick={() => setIsOpen(false)}>
              <CloseIcon />
            </button>
          </Header>
          <p className="bg-[#F7F5F8] text-center py-2 px-8 text-xs leading-[16.8px] text-[#61666B] break-keep">
            폭언, 도배, 상업적 홍보 등 불편을 주는 메시지는 신고할 수 있으며,
            악의적인 사용자는 채팅이 제한될 수 있습니다.
          </p>
          <MessageList ref={messageListRef}>
            <p className="mt-4 text-center text-xs leading-[14.32px] text-[#61666B]">
              {MESSAGES_IN_HOURS}시간 이내의 메시지만 표시됩니다.
            </p>
            <MessageBubble className="mt-4 mb-2 inline-block bg-[#F5F7FF]">
              {greetingRef.current}
            </MessageBubble>
            {isLoading && messages.length === 0 && (
              <LoadingIndicator>메시지 로드 중...</LoadingIndicator>
            )}
            {messages.map((msg, i) => {
              const shouldBlind =
                msg.isBlinded ||
                (msg.reporters || []).length >= BLINDING_THRESHOLD
              const isOwn = userInfo.loggedIn && msg.sender === userInfo.userId
              const isSameSenderAsPrev =
                i > 0 && messages[i - 1].sender === msg.sender

              return (
                <MessageItem
                  key={msg.id}
                  isBanned={bannedUsers.has(msg.sender)}
                >
                  {!isOwn && !isSameSenderAsPrev && (
                    <div className="flex gap-x-1 items-center pt-2 mb-2">
                      <p className={`text-[#61666B] text-xs leading-[14.5px]`}>
                        {msg.senderName}
                      </p>
                      {msg.senderMembership === "member" && (
                        <BadgeImg width={16} height={16} />
                      )}
                    </div>
                  )}
                  <div
                    className={`flex items-end ${
                      isOwn ? "flex-row-reverse" : ""
                    }`}
                  >
                    <MessageBubble
                      isOwn={isOwn}
                      shouldBlind={shouldBlind}
                      onTouchStart={
                        isOwn || shouldBlind
                          ? undefined
                          : e => handleTouchStart(e, msg.id, msg.sender)
                      }
                      onTouchEnd={handleTouchEnd}
                      onTouchMove={handleTouchEnd}
                    >
                      {!shouldBlind
                        ? replaceCustomEmojis(msg.text)
                        : (msg.reporters || []).length >= BLINDING_THRESHOLD
                          ? "신고 누적으로 가려진 메시지입니다."
                          : "운영자에 의해 가려진 메시지입니다."}
                    </MessageBubble>
                    <MessageTime
                      className={`mx-1 text-[10px] leading-[12px] text-[#CBCDD6] ${
                        shouldBlind || isOwn ? "" : "md:hover:text-transparent"
                      }`}
                    >
                      {moment(msg.timestamp?.toDate()).format("HH:mm")}
                      {!isOwn && userInfo.loggedIn && !shouldBlind && (
                        <KebabMenu
                          isOwn={isOwn}
                          className="kebab-menu"
                          onClick={() => openReportMenu(msg.id, msg.sender)}
                        >
                          <div />
                          <div />
                          <div />
                        </KebabMenu>
                      )}
                    </MessageTime>
                  </div>
                </MessageItem>
              )
            })}
            <ReportMenu
              messageListRef={messageListRef}
              isOpen={reportMenuState.isOpen}
              onClose={closeReportMenu}
              onReport={handleReportClick}
              hasReported={reportMenuState.hasReported}
              onBlind={handleBlindClick}
            />
            <div ref={messagesEndRef} />
          </MessageList>

          <InputContainer>
            <InputWrapper
              className={`${
                !userInfo.loggedIn || !!userBanEndTime
                  ? "bg-[#F6F6F6]"
                  : "bg-white"
              }`}
            >
              <Input
                ref={messageInputRef}
                onChange={handleInputChange}
                onKeyDown={handleInputKeyDown}
                placeholder={getInputPlaceholder()}
                disabled={!userInfo.loggedIn || !!userBanEndTime}
                maxLength={MAX_MESSAGE_LENGTH}
                rows={1}
              />
              <div className="flex items-center gap-x-2 my-2 mr-2">
                <div className="relative">
                  {userInfo.loggedIn && (
                    <EmojiIcon
                      onMouseOver={() => setShowEmojiTooltip(true)}
                      onMouseOut={() => setShowEmojiTooltip(false)}
                      // onClick={() => setIsEmojiPanelOpen(!isEmojiPanelOpen)}
                    />
                  )}
                  {showEmojiTooltip && (
                    <p className="absolute -top-[29px] rounded-md px-2 whitespace-nowrap -right-1 bg-black/80 text-white text-xs leading-6">
                      준비 중이에요!
                    </p>
                  )}
                </div>
                <SendIcon
                  className="cursor-pointer"
                  onClick={handleSendMessage}
                />
              </div>
            </InputWrapper>
            {isEmojiPanelOpen && (
              <EmojiPanel>
                {Object.entries(customEmojis).map(([category, data]) => (
                  <div key={category}>
                    <h4 className="font-medium text-sm text-gray-600 mb-1">
                      {data.title}
                    </h4>
                    <div className="flex flex-wrap gap-2 mb-3">
                      {Object.entries(data.emojis).map(([code, emoji]) => (
                        <button
                          key={code}
                          onClick={() => insertEmoji(code)}
                          className="w-8 h-8 flex items-center justify-center hover:bg-gray-100 rounded"
                        >
                          {emoji}
                        </button>
                      ))}
                    </div>
                  </div>
                ))}
              </EmojiPanel>
            )}
          </InputContainer>
        </ChatWindow>
      )}
    </Container>
  )
}

const Container = styled.div`
  position: relative;
`

const ChatButton = styled.div`
  position: relative;
  cursor: pointer;
`

const NewMessageBadge = styled.div`
  position: absolute;
  top: 3px;
  right: 3px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #ff0000;
  border: 1px solid white;
`

const ChatWindow = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 375px;
  height: 812px;
  background: white;
  max-width: 90vw;
  max-height: 70vh;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;

  @media (max-width: 800px) {
    position: fixed;
    inset: 0;
    box-shadow: none;
    max-width: unset;
    max-height: unset;
    width: 100%;
    height: 100%;
  }
`

const Header = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  height: 3rem;
  border-bottom: 1px solid #eaeaea;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const MessageList = styled.div`
  position: relative;
  flex: 1;
  overflow-y: auto;
  padding-left: 24px;
  padding-right: 24px;
  > :first-child {
    margin-top: 1rem;
  }
  > :last-child {
    margin-bottom: 1rem;
  }
`

const MessageItem = styled.div`
  margin-bottom: 0.5rem;
  opacity: ${props => (props.isBanned ? 0.5 : 1)};
`

const MessageBubble = styled.p`
  max-width: 70%;
  font-size: 14px;
  line-height: 16.7px;
  padding: 7.65px 12px;
  border: 1px solid #e7e7e8;
  border-radius: 8px;
  word-break: break-all;
  white-space: pre-wrap;

  ${({ isOwn, shouldBlind }) => {
    if (shouldBlind) {
      return `
      color: #b8b8b8;
      background-color: #f6f6f6;
    `
    }
    if (isOwn) {
      return `
      background-color: #e9ebfa;
    `
    }
    return ""
  }}
`

const MessageTime = styled.div`
  display: inline-block;
  position: relative;

  @media (min-width: 800px) {
    &:hover .kebab-menu {
      display: flex;
    }
  }
`

const KebabMenu = styled.div`
  display: none;
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  padding: 2px 8px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 2px;
  background-color: rgba(0, 0, 0, 0.1);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  > div {
    border-radius: 50%;
    background-color: white;
    width: 2.5px;
    height: 2.5px;
  }
`

const LoadingIndicator = styled.div`
  text-align: center;
  padding: 10px;
  color: #666;
`

const InputContainer = styled.div`
  position: relative;
  background-color: #f5f7ff;
`

const InputWrapper = styled.div`
  display: flex;
  margin: 8px;
  margin-bottom: 24px;
  align-items: flex-end;
  border: solid 1px #eaeaea;
  border-radius: 4px;
`

const EmojiPanel = styled.div`
  background: white;
  padding: 16px;
  max-height: 300px;
  overflow-y: auto;
`

const Input = styled.textarea`
  flex: 1;
  border: none;
  outline: none;
  padding: 12px;
  font-size: 14px;
  resize: none; /* Prevents manual resizing by the user */
  overflow: hidden; /* Hides any scrollbars */
  word-break: break-all;

  ::placeholder {
    color: #8d9195;
  }

  &:disabled {
    cursor: not-allowed;
  }

  @media (max-width: 800px) {
    font-size: 16px;
  }
`

export default ChatWidget
