// hooks/useOnlineStatus.js
import { useEffect } from "react"
import { db, Timestamp } from "../common/firebase.utils"

export function useOnlineStatus(userId, isLoggedIn) {
  useEffect(() => {
    if (!isLoggedIn) return

    const statusRef = db.collection("status").doc(userId)

    const updateStatus = async state => {
      try {
        await statusRef.set({
          state,
          lastChanged: Timestamp.now()
        })
      } catch (error) {
        console.error(`Error updating ${state} status:`, error)
      }
    }

    // Set initial online status
    updateStatus("online")

    const handleVisibilityChange = () => {
      updateStatus(document.hidden ? "offline" : "online")
    }

    window.addEventListener("beforeunload", () => updateStatus("offline"))
    document.addEventListener("visibilitychange", handleVisibilityChange)

    return () => {
      window.removeEventListener("beforeunload", () => updateStatus("offline"))
      document.removeEventListener("visibilitychange", handleVisibilityChange)
      updateStatus("offline")
    }
  }, [userId, isLoggedIn])
}
